import { createSlice } from '@reduxjs/toolkit';
import { LIGHT_THEME } from '#/user/themes';
import { MOBILE_MAX_BREAK_NUMBER } from '#/shared/constants';

const initialState = {
  isLayerSettingsOpened: false,
  sidePanelIsMinimized: false,
  mobileMode: false,
  theme: LIGHT_THEME,
  alertBannerIsVisible: false,
  globalAlertTimestamp: null,
  selectedLanguage: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSidePanelIsMinimized(state, action) {
      state.sidePanelIsMinimized = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    viewportWidthChanged(state, action) {
      state.mobileMode = action.payload <= MOBILE_MAX_BREAK_NUMBER;
    },
    setAlertBannerVisibility(state, action) {
      state.alertBannerIsVisible = action.payload;
    },
    setglobalAlertTimestamp(state, action) {
      state.globalAlertTimestamp = action.payload;
    },
    setLayerSettings(state, action) {
      state.isLayerSettingsOpened = action.payload;
    },
    setSelectedLanguage(state, action) {
      state.selectedLanguage = action.payload;
    }
  }
});

export const {
  setSidePanelIsMinimized,
  setTheme,
  viewportWidthChanged,
  setAlertBannerVisibility,
  setglobalAlertTimestamp,
  setLayerSettings,
  setSelectedLanguage
} = userSlice.actions;
export default userSlice.reducer;
