import { createSlice } from '@reduxjs/toolkit';
import { itineraryToTransitive as itineraryToTransitiveDefault } from '@opentripplanner/transitive-overlay';
import { itineraryToTransitive as itineraryToTransitiveMapLibre } from '@opentripplanner/transitive-overlay-ml';
import coreUtils from '@opentripplanner/core-utils';
import { getShortRouteLabelFromLeg } from '#/trip-planner/utils/routes';
import { modesContainTransit } from '#/trip-planner/utils/mode';
import otpUiConfig from '#/otp-ui-config';
import {
  parseFromOtpApiDateAndTimeStrings,
  formatToOtpApiDateAndTimeStrings
} from '#/shared/utils/date-time';
import {
  getDefaultQuery,
  planParamsToQuery
} from '#/trip-planner/utils/current-query';
import {
  DATE_SELECTOR,
  FEATURE_FLAGS,
  TIME_SELECTOR
} from '#/shared/constants';

const { MAPLIBRE_MODE: mapLibreMode } = FEATURE_FLAGS;
const defaultQuery = getDefaultQuery();
const urlQuery =
  typeof window !== 'undefined'
    ? planParamsToQuery(window.location.search.slice(1))
    : {};
const urlDate = urlQuery[DATE_SELECTOR];
const urlTime = urlQuery[TIME_SELECTOR];

const initialState = {
  plan: null,
  planError: null,
  activeItinerary: null,
  itineraryBounds: null,
  transitiveData: null,
  activeItineraryIsTransit: true,
  tripTimestamp: (urlDate && urlTime
    ? parseFromOtpApiDateAndTimeStrings(urlDate, urlTime)
    : new Date()
  ).getTime(),

  currentQuery: {
    // url param values take precedence over OTP defaults in initial query
    ...defaultQuery,
    ...urlQuery
  }
};

const { getItineraryBounds, getLegBounds } = coreUtils.itinerary;
const { companies } = otpUiConfig;

const getItineraryToTransitive = () => {
  return mapLibreMode
    ? itineraryToTransitiveMapLibre
    : itineraryToTransitiveDefault;
};

const getActiveItineraryNodes = activeItinerary => {
  const itineraryToTransitive = getItineraryToTransitive();
  const transitiveDataObject = itineraryToTransitive(activeItinerary, {
    companies,
    getRouteLabel: getShortRouteLabelFromLeg
  });

  return {
    activeItinerary,
    activeItineraryIsTransit: modesContainTransit(activeItinerary.legs),
    itineraryBounds: getItineraryBounds(activeItinerary),
    transitiveData: transitiveDataObject
  };
};

const tripPlannerSlice = createSlice({
  name: 'tripPlanner',
  initialState,
  reducers: {
    fetchTripOptionsSuccess(state, action) {
      return {
        ...state,
        plan: action.payload,
        planError: null,
        ...getActiveItineraryNodes(action.payload.itineraries[0])
      };
    },
    fetchTripOptionsError(state, action) {
      return {
        ...state,
        activeItinerary: null,
        itineraryBounds: null,
        transitiveData: null,
        focusedLegBounds: null,
        plan: null,
        planError: action.payload
      };
    },
    clearTripOptions(state) {
      return {
        ...state,
        activeItinerary: null,
        itineraryBounds: null,
        transitiveData: null,
        focusedLegBounds: null,
        plan: null,
        planError: null
      };
    },
    setActiveItinerary(state, action) {
      return {
        ...state,
        ...getActiveItineraryNodes(action.payload),
        focusedLegBounds: null
      };
    },
    itineraryLegFocused(state, action) {
      state.focusedLegBounds = getLegBounds(
        state.activeItinerary.legs[action.payload.focusedLegIndex]
      );
    },
    itineraryLegCleared(state) {
      state.focusedLegBounds = null;
    },
    setTripTimestamp(state, action) {
      const { payload: tripTimestamp } = action;
      const [otpTripDate = null, otpTripTime = null] = tripTimestamp
        ? formatToOtpApiDateAndTimeStrings(new Date(tripTimestamp))
        : [];

      return {
        ...state,
        tripTimestamp,
        currentQuery: {
          ...state.currentQuery,
          [DATE_SELECTOR]: otpTripDate,
          [TIME_SELECTOR]: otpTripTime
        }
      };
    },
    setCurrentQueryParam(state, action) {
      state.currentQuery = {
        ...state.currentQuery,
        ...action.payload
      };
    },
    clearCurrentQueryParam(state, action) {
      state.currentQuery[action.payload] = null;
    }
  }
});

export const {
  fetchTripOptionsSuccess,
  fetchTripOptionsError,
  clearTripOptions,
  setActiveItinerary,
  itineraryLegFocused,
  itineraryLegCleared,
  setTripTimestamp,
  setCurrentQueryParam,
  clearCurrentQueryParam
} = tripPlannerSlice.actions;
export default tripPlannerSlice.reducer;

export const selectCurrentQuery = s => s.tripPlanner.currentQuery;
